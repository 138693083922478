var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "enkrypt-not-found-snackbar" },
    [
      _c(
        "mew-popup",
        {
          staticClass: "popup",
          attrs: {
            show: _vm.show,
            "has-buttons": false,
            "has-title": false,
            "has-padding": false,
            "left-btn": _vm.leftBtn,
            "max-width": "360",
            "has-body-content": "",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "enkrypt-not-found-content-container pb-6 px-6 height--full width--full d-flex flex-column",
            },
            [
              _c("div", { staticClass: "d-flex align-center" }, [
                _c("img", {
                  attrs: {
                    alt: "enkrypt logo",
                    src: require("@/assets/images/icons/enkrypt/icon-enkrypt-colored.svg"),
                    height: "20px",
                  },
                }),
              ]),
              _c("div", { staticClass: "mew-heading-4 mt-5" }, [
                _vm._v(" Please install Enkrypt or enable it in your "),
                _c("br"),
                _vm._v(" browser "),
              ]),
              _c(
                "div",
                [
                  _c(
                    "mew-button",
                    {
                      staticClass: "extension-btn mt-5 text-transform--none",
                      staticStyle: { "border-radius": "40px !important" },
                      attrs: {
                        "has-full-width": true,
                        "btn-size": "large",
                        "color-theme": "#7E44F2",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.downloadAndClose.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "mr-3",
                        attrs: {
                          width: "25px",
                          height: "25px",
                          src: _vm.browserLogo,
                        },
                      }),
                      _vm._v(" " + _vm._s(_vm.text) + " "),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }